"use client";

import React, { useEffect, useState } from "react";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { product } from "@prisma/client";
import ProductComponent from "@/components/ProductComponent";
import SkeletonLoader from "./SkeletonLoader";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ProductWithLikes } from "@/interfaces/responses/catalog.interface";
export default function ModalPreview({
  product,
  setProductModal
}: {
  product: ProductWithLikes;
  setProductModal: any;
}) {
  const [productData, setProductData] = useState(product);
  const [printTypes, setPrintTypes] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  useEffect(() => {
    if (!product) return;
    console.log("FETCHIGN MODAL PREVIEW");
    const productData = fetch(`/api/shop/product/${product.titleSlug}-${product.id}`).then(response => response.json()).then(data => setProductData(data));
    const printTypes = fetch(`/api/shop/product/${product.titleSlug}-${product.id}/print-types`).then(response => response.json()).then(data => {
      console.log("Print types:", data);
      setPrintTypes(data);
    });
    const relatedProducts = fetch(`/api/shop/product/${product.titleSlug}-${product.id}/recommendations/`).then(response => response.json()).then(data => setRelatedProducts(data));
  }, [product]);
  return <Dialog open={!!product} onClose={setProductModal} className="relative z-10" data-sentry-element="Dialog" data-sentry-component="ModalPreview" data-sentry-source-file="ModalPreview.tsx">
      <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" data-sentry-element="DialogBackdrop" data-sentry-source-file="ModalPreview.tsx" />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center text-center sm:p-0 md:p-4">
          <DialogPanel transition className="relative w-full transform overflow-hidden rounded-lg bg-white px-1 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-4/5 sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95" data-sentry-element="DialogPanel" data-sentry-source-file="ModalPreview.tsx">
            <div className="absolute right-0 top-0 pr-4 pt-4">
              <button type="button" className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2" onClick={() => setProductModal(undefined)}>
                <span className="sr-only">Close</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" data-sentry-element="XMarkIcon" data-sentry-source-file="ModalPreview.tsx" />
              </button>
            </div>
            {product ? <ProductComponent product={product} printTypes={printTypes} relatedProducts={relatedProducts} setProductModal={setProductModal} /> : <SkeletonLoader />}
          </DialogPanel>
        </div>
      </div>
    </Dialog>;
}