"use client";

import React, { useEffect, useState } from "react";
import { product } from "@prisma/client";
import InfiniteScroll from "react-infinite-scroll-component";
import { ProductPreview } from "./ProductPreview";
import { Pagination, ProductWithLikes } from "../interfaces/responses/catalog.interface";
import Masonry from "react-masonry-css";
import ModalPreview from "./ModalPreview";
import { useRouter } from "next/navigation";
export function Catalog({
  data
}: {
  data: {
    products: ProductWithLikes[];
    pagination: Pagination;
    searchQuery: string | undefined;
    productModalInitial: ProductWithLikes | undefined;
  };
}): React.JSX.Element {
  const [products, setProducts] = useState(data.products);
  const [page, setPage] = useState(1);
  const [productModal, setProductModal] = useState<ProductWithLikes | undefined>(data.productModalInitial);
  const fetchSearchResults = (query, page = 1) => {
    fetch(`/api/shop/search?q=${query}&page=${page}`).then(response => response.json()).then(data => {
      if (page === 1) {
        console.log("--- Replacing products", data.products.length, page);
        setProducts(data.products);
      } else {
        setProducts([...products, ...data.products]);
      }
    }).catch(error => console.error("Error fetching products:", error));
  };
  const fetchMoreData = () => {
    if (!data.searchQuery) {
      fetch(`/api/shop/catalog?page=${page}`).then(response => response.json()).then(data => {
        setProducts([...products, ...data.products]);
        setPage(page + 1);
      }).catch(error => console.error("Error fetching more products:", error));
    } else {
      fetchSearchResults(data.searchQuery, page + 1);
      setPage(page + 1);
    }
  };

  // Refresh search results when search query changes
  useEffect(() => {
    if (data.searchQuery) {
      fetchSearchResults(data.searchQuery);
    }
  }, [data.searchQuery]);
  const router = useRouter();
  const changeProductModal = (product: ProductWithLikes | undefined) => {
    console.log("changing product modal", product);
    if (product) {
      console.log("setting product modal", product);
      setProductModal(product);
      window.history.pushState({}, "", `/product/${product.titleSlug}-${product.id}`);
    } else {
      console.log("setting product modal to undefined");
      setProductModal(undefined);
      window.history.pushState({}, "", `/`);
    }
  };
  return <div className="" data-sentry-component="Catalog" data-sentry-source-file="Catalog.tsx">
      <ModalPreview product={productModal}
    // open={!!productModal}
    setProductModal={changeProductModal} data-sentry-element="ModalPreview" data-sentry-source-file="Catalog.tsx" />
      <InfiniteScroll dataLength={products?.length} next={fetchMoreData} hasMore={page <= data.pagination.totalPages - 1} loader={<div>Loading...</div>} scrollThreshold={0.8} endMessage={<p className="pt-6 text-center">You have seen it all!</p>} data-sentry-element="InfiniteScroll" data-sentry-source-file="Catalog.tsx">
        <Masonry breakpointCols={{
        default: 5,
        1100: 4,
        900: 3,
        800: 2,
        200: 1
      }} className="flex" columnClassName="" data-sentry-element="Masonry" data-sentry-source-file="Catalog.tsx">
          {products.map((product: ProductWithLikes, i: number) => <ProductPreview key={i} product={product} setProductModal={changeProductModal} />)}
        </Masonry>
      </InfiniteScroll>
    </div>;
}